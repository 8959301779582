// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PromotionCodes-module-container-rlLT3{position:relative;display:flex;flex-direction:column;align-items:center;justify-content:center;margin-inline:auto;gap:50px}@media(min-width: 1280px){.PromotionCodes-module-container-rlLT3{padding-inline:10px}}@media(max-width: 768px){.PromotionCodes-module-container-rlLT3{gap:25px}}.PromotionCodes-module-textContainer-kwahk{display:flex;max-width:832px;flex-direction:column;align-items:center;gap:25px;text-align:center}@media(max-width: 768px){.PromotionCodes-module-textContainer-kwahk{align-items:stretch;margin-inline:auto}}.PromotionCodes-module-title-atgAD{font-size:45px;line-height:55px}@media(max-width: 768px){.PromotionCodes-module-title-atgAD{font-size:20px;line-height:33px}}.PromotionCodes-module-description-gWP-C{font-size:20px;line-height:33px}@media(max-width: 768px){.PromotionCodes-module-description-gWP-C{font-size:16px;line-height:23px}}.PromotionCodes-module-promotionsContainer-f0r-m{width:100%;display:grid;grid-template-columns:repeat(auto-fill, minmax(297px, 1fr));grid-gap:24px;gap:24px}@media(max-width: 768px){.PromotionCodes-module-promotionsContainer-f0r-m{grid-template-columns:1fr;gap:10px}}.PromotionCodes-module-loadingOverlay-3imsg{z-index:1;position:absolute;display:flex;justify-content:center;align-items:center;top:0;bottom:0;inset-inline-start:0;width:100%;background-color:rgba(255,255,255,.7)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PromotionCodes-module-container-rlLT3",
	"textContainer": "PromotionCodes-module-textContainer-kwahk",
	"title": "PromotionCodes-module-title-atgAD",
	"description": "PromotionCodes-module-description-gWP-C",
	"promotionsContainer": "PromotionCodes-module-promotionsContainer-f0r-m",
	"loadingOverlay": "PromotionCodes-module-loadingOverlay-3imsg"
};
export default ___CSS_LOADER_EXPORT___;
