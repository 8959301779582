// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button-module-button--u78\\+{display:inline-flex;padding-block:16px;padding-inline:10px;border-radius:8px;font-size:14px;line-height:20px;text-decoration:none;cursor:pointer}.Button-module-buttonLightMode-GR6s4{background-color:rgba(0,0,0,0);color:#000}.Button-module-buttonLightMode-GR6s4:hover,.Button-module-buttonLightMode-GR6s4:focus{color:#5c615e;background-color:#f9f8f8}.Button-module-buttonLightMode-GR6s4:active{background-color:#3567ff;background-color:var(--colors-accent-accent-100, #3567ff);color:#000}.Button-module-buttonLightMode-GR6s4.Button-module-buttonActive-5HLdA{background-color:#f9f8f8;color:#070707}.Button-module-buttonLightMode-GR6s4.Button-module-buttonActive-5HLdA:hover,.Button-module-buttonLightMode-GR6s4.Button-module-buttonActive-5HLdA:focus{background-color:#f9f8f8;color:#5c615e}.Button-module-buttonLightMode-GR6s4.Button-module-buttonActive-5HLdA:active{background-color:#3567ff;background-color:var(--colors-accent-accent-100, #3567ff);color:#000}.Button-module-buttonDarkMode-xJECl{background-color:rgba(0,0,0,0);color:#fff}.Button-module-buttonDarkMode-xJECl:hover,.Button-module-buttonDarkMode-xJECl:focus{background-color:#131313;color:#b8b8b8}.Button-module-buttonDarkMode-xJECl:active{background-color:#3567ff;background-color:var(--colors-accent-accent-100, #3567ff);color:#000}.Button-module-buttonDarkMode-xJECl.Button-module-buttonActive-5HLdA{background-color:#131313;color:#fff}.Button-module-buttonDarkMode-xJECl.Button-module-buttonActive-5HLdA:hover,.Button-module-buttonDarkMode-xJECl.Button-module-buttonActive-5HLdA:focus{color:#b8b8b8;background-color:#131313}.Button-module-buttonDarkMode-xJECl.Button-module-buttonActive-5HLdA:active{background-color:#3567ff;background-color:var(--colors-accent-accent-100, #3567ff);color:#000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Button-module-button--u78+",
	"buttonLightMode": "Button-module-buttonLightMode-GR6s4",
	"buttonActive": "Button-module-buttonActive-5HLdA",
	"buttonDarkMode": "Button-module-buttonDarkMode-xJECl"
};
export default ___CSS_LOADER_EXPORT___;
