// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Telephone-module-control-2CWR8{display:flex;flex-basis:auto;gap:16px}[dir=rtl] .Telephone-module-control-2CWR8{flex-direction:row-reverse;justify-content:flex-end}.Telephone-module-code-HByGE{padding-block:10px 0;min-width:115px}.Telephone-module-phoneSelectBlock-1WLSN{display:flex}.Telephone-module-telephoneFullWidth-R8fZG{width:100%}.Telephone-module-phoneContainer-wMv9r{width:100%}.Telephone-module-prefix-mya3m{margin-inline-end:8px;top:24px;height:20px;position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": "Telephone-module-control-2CWR8",
	"code": "Telephone-module-code-HByGE",
	"phoneSelectBlock": "Telephone-module-phoneSelectBlock-1WLSN",
	"telephoneFullWidth": "Telephone-module-telephoneFullWidth-R8fZG",
	"phoneContainer": "Telephone-module-phoneContainer-wMv9r",
	"prefix": "Telephone-module-prefix-mya3m"
};
export default ___CSS_LOADER_EXPORT___;
