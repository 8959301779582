// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchCard-module-searchCard-2dwzi{display:grid;grid-gap:20px;gap:20px;padding:20px;border-radius:8px;margin-top:25px;background-color:#fbfbfb}@media(min-width: 768px){.SearchCard-module-searchCard-2dwzi{gap:20px;padding:25px}}.SearchCard-module-searchCard-2dwzi.SearchCard-module-searchCardWithImage-vWkfN{grid-template-columns:80px auto}@media(min-width: 768px){.SearchCard-module-searchCard-2dwzi.SearchCard-module-searchCardWithImage-vWkfN{grid-template-columns:160px auto}}.SearchCard-module-imageLink-WpC7y{display:block;width:100%}@media(min-width: 768px){.SearchCard-module-searchCardWithImage-vWkfN .SearchCard-module-imageLink-WpC7y{grid-area:1/1/3/2}}.SearchCard-module-image-9hCOY{display:block;width:100%}.SearchCard-module-titleLink-ylkhl{text-decoration:none}.SearchCard-module-title-zdr\\+a{margin-bottom:10px}@media(min-width: 768px){.SearchCard-module-title-zdr\\+a{margin-bottom:18px;font-size:20px}}.SearchCard-module-description-Rtumz{display:-webkit-box;-webkit-line-clamp:3;line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;color:#3a3d3f}.SearchCard-module-link-dKhsp{display:flex;justify-content:center;align-self:start;padding:9px;border-radius:8px;background-color:#000;font-size:14px;font-weight:450;line-height:20px;color:#fff;text-decoration:none;white-space:nowrap}@media(min-width: 768px){.SearchCard-module-link-dKhsp{width:-webkit-max-content;width:max-content;padding:14px}}.SearchCard-module-link-dKhsp:active{background-color:#3567ff;background-color:var(--colors-accent-accent-100, #3567ff)}@media(hover: hover){.SearchCard-module-link-dKhsp:hover{background-color:#3567ff;background-color:var(--colors-accent-accent-100, #3567ff)}}.SearchCard-module-searchCardWithImage-vWkfN .SearchCard-module-link-dKhsp{grid-area:2/1/3/3}@media(min-width: 768px){.SearchCard-module-searchCardWithImage-vWkfN .SearchCard-module-link-dKhsp{grid-area:2/2/3/3}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchCard": "SearchCard-module-searchCard-2dwzi",
	"searchCardWithImage": "SearchCard-module-searchCardWithImage-vWkfN",
	"imageLink": "SearchCard-module-imageLink-WpC7y",
	"image": "SearchCard-module-image-9hCOY",
	"titleLink": "SearchCard-module-titleLink-ylkhl",
	"title": "SearchCard-module-title-zdr+a",
	"description": "SearchCard-module-description-Rtumz",
	"link": "SearchCard-module-link-dKhsp"
};
export default ___CSS_LOADER_EXPORT___;
