// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DatePickerInput-module-calendarBox--dTOM{padding-block:20px;padding-block:var(--Spacing-5, 20px);padding-inline:24px;padding-inline:var(--Spacing-6, 24px);border-radius:8px;border-radius:var(--radius-md, 8px);border:1px solid #d3d3d3;border:1px solid var(--colors-border-border-quartiary, #d3d3d3);background:#fff;background:var(--colors-brand-primary-coton-white, #fff)}.DatePickerInput-module-inputBox-Dpc2W{position:relative;max-width:300px}.DatePickerInput-module-inputBox-Dpc2W .DatePickerInput-module-input-wPI05{padding-block:6px;padding-block:var(--spacing-1_5, 6px);padding-inline:12px;padding-inline:var(--spacing-3, 12px);padding-inline-end:32px;padding-inline-end:var(--spacing-8, 32px)}.DatePickerInput-module-inputBox-Dpc2W .DatePickerInput-module-icon-CSSl0{position:absolute;top:50%;transform:translateY(-50%);margin-inline-start:calc(-1*32px);margin-inline-start:calc(-1*var(--spacing-8, 32px));cursor:pointer;height:18px;width:18px;color:#2d2926;color:var(--colors-text-text-primary, #2d2926)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarBox": "DatePickerInput-module-calendarBox--dTOM",
	"inputBox": "DatePickerInput-module-inputBox-Dpc2W",
	"input": "DatePickerInput-module-input-wPI05",
	"icon": "DatePickerInput-module-icon-CSSl0"
};
export default ___CSS_LOADER_EXPORT___;
