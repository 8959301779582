// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldWrapper-module-container-2MSNs{display:grid;grid-gap:10px;gap:10px;grid-template-areas:\"label\" \"control\"}.FieldWrapper-module-container-2MSNs .FieldWrapper-module-control-eMA9p{grid-area:control}.FieldWrapper-module-container-2MSNs .FieldWrapper-module-error-d\\+MqR{color:#ed6d6b;grid-area:error;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.FieldWrapper-module-container-2MSNs.FieldWrapper-module-hasError-OgGl3{grid-template-areas:\"label\" \"control\" \"error\"}.FieldWrapper-module-container-2MSNs.FieldWrapper-module-hasError-OgGl3 div[class*=react-select__control],.FieldWrapper-module-container-2MSNs.FieldWrapper-module-hasError-OgGl3 div[class*=react-select__menu],.FieldWrapper-module-container-2MSNs.FieldWrapper-module-hasError-OgGl3 input,.FieldWrapper-module-container-2MSNs.FieldWrapper-module-hasError-OgGl3 textarea{border-color:#dc283e}.FieldWrapper-module-container-2MSNs.FieldWrapper-module-checkable-CgKIv{gap:6px;grid-template-columns:auto 1fr;grid-template-areas:\"control label\"}.FieldWrapper-module-container-2MSNs.FieldWrapper-module-checkable-CgKIv .FieldWrapper-module-label-e7Mxf{font-size:16px;display:grid;grid-gap:6px;gap:6px}.FieldWrapper-module-container-2MSNs.FieldWrapper-module-checkable-CgKIv.FieldWrapper-module-hasError-OgGl3{grid-template-areas:\"control label\" \"error error\"}.FieldWrapper-module-container-2MSNs.FieldWrapper-module-isFilled-ecvN4:not(.FieldWrapper-module-hasError-OgGl3) input:not(:focus,:read-only),.FieldWrapper-module-container-2MSNs.FieldWrapper-module-isFilled-ecvN4:not(.FieldWrapper-module-hasError-OgGl3) textarea:not(:focus,:read-only){border-color:#101820}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FieldWrapper-module-container-2MSNs",
	"control": "FieldWrapper-module-control-eMA9p",
	"error": "FieldWrapper-module-error-d+MqR",
	"hasError": "FieldWrapper-module-hasError-OgGl3",
	"checkable": "FieldWrapper-module-checkable-CgKIv",
	"label": "FieldWrapper-module-label-e7Mxf",
	"isFilled": "FieldWrapper-module-isFilled-ecvN4"
};
export default ___CSS_LOADER_EXPORT___;
