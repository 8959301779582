// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroBackground-module-heroBackground-hvgV1 .imageWrapper{position:absolute;top:0;inset-inline-start:0;width:100%;height:100%}.HeroBackground-module-heroBackground-hvgV1 .imageWrapper picture{position:relative;display:block;width:100%;height:100%;overflow:hidden}.HeroBackground-module-heroBackground-hvgV1 .imageWrapper picture .image{position:absolute;top:0;inset-inline:0;bottom:0;display:block;width:100%;height:100%;object-fit:cover}.HeroBackground-module-heroBackground-hvgV1 .videoContainer{position:absolute;top:0;inset-inline-start:0}.HeroBackground-module-heroBackground-hvgV1 .videoContainer,.HeroBackground-module-heroBackground-hvgV1 .videoContainer .video-player__wrapper,.HeroBackground-module-heroBackground-hvgV1 .videoContainer .video-player__aspect-ratio-box{width:100%;height:100%}@media(max-width: 768px){.HeroBackground-module-mobileMediaAtTop-K4hJm .imageWrapper{height:225px;position:static;position:initial}.HeroBackground-module-mobileMediaAtTop-K4hJm .videoContainer{height:225px;position:static;position:initial}}.HeroBackground-module-isHeroCardsShown-7jYHW .imageWrapper,.HeroBackground-module-isHeroCardsShown-7jYHW .videoContainer{height:calc(100vh - var(--fixed-hero-cards-margin) - (var(--hero-cards-image-size) + 2*var(--hero-cards-padding))/2)}.hero-banner-v2--authoring .HeroBackground-module-imageWrapper-GGhmg,.hero-banner-v2--authoring .HeroBackground-module-videoContainer--TPvh{height:500px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroBackground": "HeroBackground-module-heroBackground-hvgV1",
	"mobileMediaAtTop": "HeroBackground-module-mobileMediaAtTop-K4hJm",
	"isHeroCardsShown": "HeroBackground-module-isHeroCardsShown-7jYHW",
	"imageWrapper": "HeroBackground-module-imageWrapper-GGhmg",
	"videoContainer": "HeroBackground-module-videoContainer--TPvh"
};
export default ___CSS_LOADER_EXPORT___;
