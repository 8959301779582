// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Breadcrumbs-module-listContainer-wDvtd{display:flex;align-items:center;padding-inline:12px;padding-top:20px}.Breadcrumbs-module-dots-RCSBz{display:flex;background-color:rgba(0,0,0,0);padding-block:8px;cursor:pointer}.Breadcrumbs-module-dots-RCSBz:hover .Breadcrumbs-module-dot-0aqKQ{background-color:#3567ff}.Breadcrumbs-module-dot-0aqKQ{width:4px;height:4px;border-radius:50%;background-color:#5c5c5c;margin-inline-end:4px}.Breadcrumbs-module-darkMode-CWr5B .Breadcrumbs-module-dot-0aqKQ{background-color:#d3d3d3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": "Breadcrumbs-module-listContainer-wDvtd",
	"dots": "Breadcrumbs-module-dots-RCSBz",
	"dot": "Breadcrumbs-module-dot-0aqKQ",
	"darkMode": "Breadcrumbs-module-darkMode-CWr5B"
};
export default ___CSS_LOADER_EXPORT___;
