// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container-module-container-fC\\+aT{width:100%;max-width:100%;padding-inline:18px}@media(min-width: 480px){.Container-module-container-fC\\+aT{padding-inline:10px}}@media(min-width: 768px){.Container-module-container-fC\\+aT{max-width:1280px;margin-inline:auto;padding-inline:12px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container-module-container-fC+aT"
};
export default ___CSS_LOADER_EXPORT___;
