// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchResults-module-searchContainer-2zNDH{position:relative}.SearchResults-module-loader-g34t\\+{position:fixed;top:0;inset-inline-start:0;z-index:1;width:100%;height:100%;background-color:rgba(255,255,255,.9)}.SearchResults-module-loaderIcon-MhfhV{position:fixed;top:50%;inset-inline-start:50%;transform:translate(-50%, -50%)}[dir=rtl] .SearchResults-module-loaderIcon-MhfhV{transform:translate(50%, -50%)}.SearchResults-module-searchResultsTitle-9VwEe{display:-webkit-box;-webkit-line-clamp:3;line-clamp:3;-webkit-box-orient:vertical;margin-top:32px;overflow:hidden;font-size:26px;line-height:38px;color:#2d2926}@media(min-width: 768px){.SearchResults-module-searchResultsTitle-9VwEe{margin-top:80px;font-size:32px;line-height:44px}}.SearchResults-module-noResults-I7h2j{margin-block:54px;font-weight:500;color:#373a36}.SearchResults-module-loadMoreButton-yuJ9Z{display:flex;padding-inline:0 20px;margin-block:16px 0;margin-inline:auto;font-size:12px;font-weight:450;color:#000}.SearchResults-module-loadMoreButton-yuJ9Z::after{width:12px;height:12px;margin-top:0}[dir=rtl] .SearchResults-module-loadMoreButton-yuJ9Z::after{transform:rotate(-45deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": "SearchResults-module-searchContainer-2zNDH",
	"loader": "SearchResults-module-loader-g34t+",
	"loaderIcon": "SearchResults-module-loaderIcon-MhfhV",
	"searchResultsTitle": "SearchResults-module-searchResultsTitle-9VwEe",
	"noResults": "SearchResults-module-noResults-I7h2j",
	"loadMoreButton": "SearchResults-module-loadMoreButton-yuJ9Z"
};
export default ___CSS_LOADER_EXPORT___;
