// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dialog-module-container-Tw8r2{position:fixed;top:0;inset-inline-start:0;width:100%;height:100%;overflow:auto;display:none;align-items:center;background-color:var(--background-color);-webkit-backdrop-filter:var(--backdrop-filter);backdrop-filter:var(--backdrop-filter);padding-inline:var(--spacing-sm);z-index:100}.Dialog-module-container-Tw8r2.Dialog-module-isShown-HtvDX{display:flex}.Dialog-module-container-Tw8r2.Dialog-module-isContainerFullscreen-hhQYz{padding:0}.Dialog-module-dialog-NFcjr{position:relative;border-radius:var(--radius-md);background:var(--colors-brand-primary-coton-white);box-shadow:0 5px 15px rgba(0,0,0,.1);max-height:100%;margin-inline:auto;overflow:auto;padding-block:var(--spacing-2xl) var(--spacing-2xs);padding-inline:var(--spacing-none)}@media(min-width: 480px){.Dialog-module-dialog-NFcjr{width:var(--container-xs);padding-inline:var(--spacing-2xs)}}@media(min-width: 600px){.Dialog-module-dialog-NFcjr{width:530px}}@media(min-width: 960px){.Dialog-module-dialog-NFcjr{width:880px;padding-block:var(--spacing-2xl) var(--spacing-sl);padding-inline:var(--spacing-3xl)}}.Dialog-module-dialogDark-ihFDm{background:var(--colors-brand-secondary-black)}.Dialog-module-dialogFullscreen-JKhls{width:100%;height:100%}@media(min-width: 960px){.Dialog-module-dialogFullscreen-JKhls{max-width:1280px}}.Dialog-module-noPadding-Z8mrI{padding:0}.Dialog-module-close-rfw7N{position:absolute;inset-inline-end:30px;top:30px}.Dialog-module-dialogDark-ihFDm .Dialog-module-close-rfw7N{color:var(--colors-brand-primary-coton-white)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Dialog-module-container-Tw8r2",
	"isShown": "Dialog-module-isShown-HtvDX",
	"isContainerFullscreen": "Dialog-module-isContainerFullscreen-hhQYz",
	"dialog": "Dialog-module-dialog-NFcjr",
	"dialogDark": "Dialog-module-dialogDark-ihFDm",
	"dialogFullscreen": "Dialog-module-dialogFullscreen-JKhls",
	"noPadding": "Dialog-module-noPadding-Z8mrI",
	"close": "Dialog-module-close-rfw7N"
};
export default ___CSS_LOADER_EXPORT___;
